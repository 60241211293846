let baseUrl = window.location.origin + window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/')) + "/";
baseUrl = baseUrl.indexOf("localhost") !== -1 ? 'http://localhost:8888/' : baseUrl;
let Util = {
    serverUrl: baseUrl + 'api',
    validation: {
        email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        number: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    },
    payBy: [
        { content: 'pay_pal', value: 'PayPal', id: 'PayPal', margin: '0em' },
        { content: 'cc_avenue', value: 'CCAvenue', id: 'CCAvenue', margin: '.6em' },
    ],
    capitalizeFirstLetter: function (string) {
        return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
    },
    fileDownloadUrl: 'https://www.raakeshsaraff.com', // used for testing for now
    activityData: [
        { label: "Product Activities", downloadLink: 'https://www.raakeshsaraff.com/public/1_Product_Activity.xlsx' },
        { label: "Market Activities", downloadLink: 'https://www.raakeshsaraff.com/public/2_Market_Activity.xlsx' },
        { label: "Buyer Activities", downloadLink: 'https://www.raakeshsaraff.com/public/3_Buyer_Activity.xlsx' },
        { label: "Supplier Activities", downloadLink: 'https://www.raakeshsaraff.com/public/4_Supplier_Activity.xlsx' },
        { label: "Pricing Activities", downloadLink: 'https://www.raakeshsaraff.com/public/5_Pricing_Activity.xlsx' },
        { label: "Export Benefits and Import Duty Activities", downloadLink: 'https://www.raakeshsaraff.com/public/6_Benefits_and_Duty_Activity.xlsx' },
        { label: "Customized Strategy Activities", downloadLink: 'https://www.raakeshsaraff.com/public/7_Customised_Strategy_Activity.xlsx' }
    ],
    photoGallery: [
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Praveen_Jajoo.png', title: 'Mr. Praveen Jajoo', subTitle: 'Asst. VP- PI Industries' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Pankaj_Poddar.png', title: 'Mr. Pankaj Poddar', subTitle: 'CEO- Cosmo Films' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Saurabh_Gaurav_Kapoor.png', title: 'Mr. Saurabh & Gaurav Kapoor', subTitle: 'Directors, JSG Innotech' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Navkendar_Singh.png', title: 'Mr. Navkendar Singh', subTitle: 'Research Director, IDC India' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Ashok_Jangra.png', title: 'Mr. Ashok Jangra', subTitle: 'Country Head-South-West Asia, Logitech' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Hardesh_Chojher.png', title: 'Mr. Hardesh Chojher', subTitle: 'Senior Director - Franke Faber India Pvt Ltd' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_LD_Mittal.png', title: 'Shri. LD Mittal', subTitle: 'Chairman - Sonalika Group' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Shiv_Gupta.png', title: 'Mr. Shiv Gupta', subTitle: 'Director - Laxmi Agro' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Vivek_Jain.png', title: 'Mr. Vivek Jain', subTitle: 'MD - Action TESA' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Amit_Bhatia.png', title: 'Mr. Amit Bhatia', subTitle: 'MD - Behr Bircher Cellpack India Pvt Ltd' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Jack_Canfield.png', title: 'Mr. Jack Canfield', subTitle: 'Book Cover Launch' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Kamal_Tandon.png', title: 'Mr. Kamal Tandon', subTitle: 'ED - GAIL India' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Naresh_Arora.png', title: 'Mr. Naresh Arora', subTitle: 'MD, Parkash Dyechem' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Rahul_Goyal.png', title: 'Mr. Rahul Goyal', subTitle: 'MD, RLG Group' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Sanjay_Chawla.png', title: 'Mr. Sanjay Chawla', subTitle: 'VP, Groz Beckert India' },
        { image: '/assets/images/Media/Photo_gallery_images/Mr_Tarun_Kochar.png', title: 'Mr. Tarun Kochar', subTitle: 'Director, Vi - John Group of Comapnies' },
    ],
    galleryImages: [
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Praveen_Jajoo.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Pankaj_Poddar.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Saurabh_Gaurav_Kapoor.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Navkendar_Singh.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Ashok_Jangra.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Hardesh_Chojher.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_LD_Mittal.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Shiv_Gupta.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Vivek_Jain.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Amit_Bhatia.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Jack_Canfield.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Kamal_Tandon.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Naresh_Arora.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Rahul_Goyal.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Sanjay_Chawla.png' },
        { src: '/assets/images/Media/Photo_gallery_images/Mr_Tarun_Kochar.png' },
    ],
    interviewVideoThumbnailsCol1: [
        { thumbnail: '/assets/images/Media/Interview_video_thumbnail/Mr_Deepak_Jalan.png', title: 'Mr. Deepak Jalan', subTitle: 'MD Linc Pens & Plastics' },
        { thumbnail: '/assets/images/Media/Interview_video_thumbnail/Mr_Saurav_Gaurav_Kapoor.png', title: 'Mr. Saurav & Gaurav Kapoor', subTitle: 'Directors, JSG Innotech' },
        { thumbnail: '/assets/images/Media/Interview_video_thumbnail/Mr_Ashok_Jangra.png', title: 'Mr. Ashok Jangra', subTitle: 'Country Head-South-West Asia, Logitech' },
    ],
    testimonialData: [
        {
            name: 'Karan Hasija',
            designation: 'Serial Entrepreneur, Business Coach & Author',
            text: "This is a great handbook and guide for anyone in the export-import business, from start-ups to veterans, from owners to CXOs. This book has great value for everyone."
        },
        {
            name: 'Rajiv Talreja',
            designation: 'Entrepreneur, Business Coach,Global Speaker & Author',
            text: "The 7 core strategies Raakesh shares in this book are powerful, practical and simple to act upon. I highly recommend you read this book to learn and implement these strategies in your business and experience the transformation first hand."
        },
        {
            name: 'Rahul Jain',
            designation: 'Founder Director of Business Coaching India LLP',
            text: "Raakesh is certainly an expert in his field and the insights shared in this book are impactful enough to bring the change in your business. It is a well-written influential book for every entrepreneur and CXO. The style of writing makes it interesting to read and easy to connect."
        }
    ],
    videoPopupData: [
        { videoLink: 'https://www.youtube.com/watch?v=tdpKFVeGSMg' },
        { videoLink: 'https://www.youtube.com/watch?v=nnweH3r3Yn0' },
        { videoLink: 'https://www.youtube.com/watch?v=p0hr6qpyAlU' }
    ]
}

export default Util;
